exports.components = {
  "component---src-pages-index-de-mdx": () => import("./../../../src/pages/index.de.mdx" /* webpackChunkName: "component---src-pages-index-de-mdx" */),
  "component---src-pages-index-en-mdx": () => import("./../../../src/pages/index.en.mdx" /* webpackChunkName: "component---src-pages-index-en-mdx" */),
  "component---src-pages-index-nl-mdx": () => import("./../../../src/pages/index.nl.mdx" /* webpackChunkName: "component---src-pages-index-nl-mdx" */),
  "component---src-pages-sponsors-boels-zanders-de-tsx": () => import("./../../../src/pages/sponsors/boels_zanders.de.tsx" /* webpackChunkName: "component---src-pages-sponsors-boels-zanders-de-tsx" */),
  "component---src-pages-sponsors-boels-zanders-en-tsx": () => import("./../../../src/pages/sponsors/boels_zanders.en.tsx" /* webpackChunkName: "component---src-pages-sponsors-boels-zanders-en-tsx" */),
  "component---src-pages-sponsors-boels-zanders-nl-tsx": () => import("./../../../src/pages/sponsors/boels_zanders.nl.tsx" /* webpackChunkName: "component---src-pages-sponsors-boels-zanders-nl-tsx" */),
  "component---src-pages-sponsors-paulussen-de-tsx": () => import("./../../../src/pages/sponsors/paulussen.de.tsx" /* webpackChunkName: "component---src-pages-sponsors-paulussen-de-tsx" */),
  "component---src-pages-sponsors-paulussen-en-tsx": () => import("./../../../src/pages/sponsors/paulussen.en.tsx" /* webpackChunkName: "component---src-pages-sponsors-paulussen-en-tsx" */),
  "component---src-pages-sponsors-paulussen-nl-tsx": () => import("./../../../src/pages/sponsors/paulussen.nl.tsx" /* webpackChunkName: "component---src-pages-sponsors-paulussen-nl-tsx" */),
  "component---src-pages-work-nl-mdx": () => import("./../../../src/pages/work.nl.mdx" /* webpackChunkName: "component---src-pages-work-nl-mdx" */)
}

